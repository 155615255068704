import { useDownloadAlbumById, useMedia, useSimilarAlbum, useSimilarMedia } from '@bpm-web-app/swr-hooks';
import { useHideSwitch, useHubSwitch, useUniqueArray } from '@bpm-web-app/utils';
import { useMemo } from 'react';
import { useLibraryTabs } from '@bpm-web-app/components';
import GhostElement from '../../shared/ghost-element/ghost-element';
import { TrackList, TrackListPreset } from '../../shared/track-list/track-list';
import styles from './similar-media-detail.module.css';
import { PageNotFound } from '../page-not-found/page-not-found';
import Title from '../../title/title';

const SIMILAR_LINES_WIDTH = [90, 70];

export interface SimilarMediaDetailProps {
    mediaId: number;
    isMaxiPlayer?: boolean;
}

export function SimilarMediaDetail({ mediaId: id, isMaxiPlayer = false }: SimilarMediaDetailProps) {
    useHideSwitch(isMaxiPlayer);

    const { isDownload, hub } = useHubSwitch();
    const { libraryProperty } = useLibraryTabs();
    const { media, isLoading: isLoadingMedia, error: mediaError } = useMedia(id, isDownload);
    const { data: album, isLoading: isLoadingAlbum, error: albumError } = useDownloadAlbumById(id, isDownload);

    const { data: similarMedia, isLoading: isLoadingSimilarMedia, error: similarMediaError } = useSimilarMedia(id, !isDownload);

    const { data: similarAlbums, isLoading: isLoadingSimilarAlbum, error: similarAlbumError, mutate: mutateSimilarAlbums } = useSimilarAlbum(id, libraryProperty, isDownload);

    const isLoading = useMemo(
        () => (isDownload ? isLoadingAlbum || isLoadingSimilarAlbum : isLoadingMedia || isLoadingSimilarMedia),
        [isDownload, isLoadingAlbum, isLoadingMedia, isLoadingSimilarAlbum, isLoadingSimilarMedia]
    );

    const error = useMemo(() => (isDownload ? albumError || similarAlbumError : mediaError || similarMediaError), [albumError, isDownload, mediaError, similarAlbumError, similarMediaError]);

    const mediaName = useMemo(() => (isDownload ? album?.data?.name : media?.data?.name), [album?.data?.name, isDownload, media?.data?.name]);

    const ghostLoadingItems = useUniqueArray(20);

    const similarMediaDetail = isDownload ? similarAlbums?.data : similarMedia?.data;

    if (id === undefined || error) return <PageNotFound />;

    return (
        <>
            <Title platform={hub} title="Similar Media" />
            <div className="spacing__window">
                {mediaName && <div className={styles['similar-media-detail__title']}>Similar Tracks to {mediaName}</div>}
                {similarMediaDetail && !isLoading ? (
                    <TrackList
                        list={similarMediaDetail}
                        preset={isDownload ? TrackListPreset.Download : TrackListPreset.Stream}
                        isSortable={false}
                        onDownloadRevalidate={(downloadMedia) => {
                            if (downloadMedia) {
                                // eslint-disable-next-line no-param-reassign
                                downloadMedia.download_count += 1;
                                mutateSimilarAlbums(similarAlbums, { revalidate: false });
                            }
                        }}
                    />
                ) : (
                    <div className={styles['similar-media-detail__loading']}>
                        {ghostLoadingItems.map((uuid) => (
                            <GhostElement isRow key={`similar-media-detail-${uuid}`} itemClass={styles['similar-media-detail__loading-item']} linesWidthArray={SIMILAR_LINES_WIDTH} squareWidth={56} />
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}

export default SimilarMediaDetail;
