import React, { useCallback, MouseEvent, KeyboardEvent, useMemo, useState } from 'react';
import { useDrag } from '@use-gesture/react';
import { HLSPlayer, PLAYER_PREVIEW_SECONDS } from '@bpm-web-app/utils';
import styles from './player-wave.module.css';
import { usePlayer, usePlayerDuration } from '../../player-context';
import { PlayerWaveCanvas } from './player-wave-canvas/player-wave-canvas';
import { PlayerWaveVariant } from './types';

export function PlayerWave({ variant }: { variant: PlayerWaveVariant }) {
    const { currentTrack, onSeek, isPreviewTrack, currentDuration } = usePlayer();
    const { elapsed, startOffset } = usePlayerDuration();
    const [containerWidth, setContainerWidth] = useState(0);
    const waves = useMemo(() => currentTrack?.waves, [currentTrack?.waves]);

    const currentTrackDuration = useMemo(() => Number(currentTrack?.estimated_duration), [currentTrack?.estimated_duration]);
    const duration: number = isPreviewTrack ? currentDuration - startOffset || PLAYER_PREVIEW_SECONDS : HLSPlayer.getDuration();

    const elapsedPercentage = isPreviewTrack ? ((elapsed - startOffset) / currentTrackDuration) * 100 : (elapsed / duration) * 100;

    const startOffsetPercentage = isPreviewTrack && startOffset ? (startOffset / currentTrackDuration) * 100 : 0;

    const onClick = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation?.();
            if (!('getBoundingClientRect' in e.currentTarget)) {
                return;
            }
            const { width, left } = e.currentTarget.getBoundingClientRect();
            const offset = e.clientX - left;
            let percentage = (offset / width) * 100;
            if (percentage < 0) percentage = 0;
            if (percentage > 100) percentage = 100;
            const toSeek = (isPreviewTrack ? currentTrackDuration / 100 : duration / 100) * percentage;
            onSeek(isPreviewTrack && startOffset && toSeek < startOffset ? startOffset : toSeek);
        },
        [duration, currentTrackDuration, isPreviewTrack, onSeek, startOffset]
    );

    const onKeyDown = useCallback(
        (e: KeyboardEvent) => {
            e.stopPropagation?.();

            if (e.key === 'ArrowRight') {
                onSeek(Math.min(elapsed + 1, duration));
                return;
            }
            if (e.key === 'ArrowLeft') {
                onSeek(Math.max(elapsed - 1, 0));
            }
        },
        [elapsed, duration, onSeek]
    );

    const dragEvents = useDrag(({ event, down }) => {
        event.stopPropagation?.();
        if (!down) return;
        onClick({
            clientX: (event as any).clientX,
            currentTarget: event.currentTarget,
        } as React.MouseEvent);
    });

    if (!waves) return null;

    if (waves.dat && !waves.dat.includes('undefined')) {
        return <PlayerWaveCanvas datUrl={waves.dat} variant={variant} />;
    }

    const lockedPercentage = ((currentTrackDuration - duration) / currentTrackDuration) * 100;
    const firstHalfPercentage = startOffset ? (startOffset / currentTrackDuration) * 100 : 0;
    const secondHalfPercentage = startOffset ? ((startOffset + duration) / currentTrackDuration) * 100 : 0;

    return (
        <div
            className={styles['player-wave']}
            ref={(ref) => {
                if (ref) {
                    setContainerWidth(ref.clientWidth);
                }
            }}
        >
            <div
                {...(dragEvents as any)()}
                role="progressbar"
                aria-valuenow={elapsedPercentage}
                tabIndex={0}
                aria-valuemin={0}
                aria-live="assertive"
                aria-valuemax={100}
                className={styles['player-wave__wrapper']}
                onClick={onClick}
                onKeyDown={onKeyDown}
            >
                <img
                    className={styles['player-wave__duration']}
                    style={{ '--dim-end': `${firstHalfPercentage ?? lockedPercentage}%`, '--dim-start': `${secondHalfPercentage}%` } as React.CSSProperties}
                    src={waves.gray}
                    alt="song wave"
                    draggable={false}
                />

                <div
                    className={styles['player-wave__elapsed']}
                    style={
                        {
                            '--highlight-start': `${startOffsetPercentage}%`,
                            '--highlight-end': `${startOffsetPercentage + elapsedPercentage}%`,
                            '--mask-url': `url(${waves.gray})`,
                        } as React.CSSProperties
                    }
                />
            </div>
        </div>
    );
}
