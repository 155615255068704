import { Period, useChartData } from '@bpm-web-app/swr-hooks';
import { capitalizeFirstLetter, formatDateToString } from '@bpm-web-app/utils';
import { useMemo, useState } from 'react';
import { Chart } from '../../../chart/chart';
import Dropdown from '../../../dropdown/dropdown';
import PageSwitcher from '../../../page-switcher/page-switcher';
import styles from './overview-chart.module.css';

// ToDo: Implement container
export function ArtistOverviewChart() {
    const [period, setPeriod] = useState<Period>('week');
    const [selectedPage, setSelectedPage] = useState<number>(0);

    const pages = useMemo(() => {
        return [
            {
                text: 'Downloads',
                seriesName: 'Download',
                metric: 'download',
                enabled: true,
                onSelected: (index: number) => {
                    setSelectedPage(index);
                }
            },
            {
                text: 'Plays',
                seriesName: 'Preview',
                metric: 'preview',
                enabled: true,
                onSelected: (index: number) => {
                    setSelectedPage(index);
                }
            },
            {
                text: 'Est. Reach',
                seriesName: 'People',
                metric: 'est_reach',
                enabled: true,
                onSelected: (index: number) => {
                    setSelectedPage(index);
                }
            },
            {
                text: 'Impressions',
                seriesName: 'Impression',
                metric: 'impression',
                enabled: true,
                onSelected: (index: number) => {
                    setSelectedPage(index);
                }
            },
        ];
    }, []);

    const metric = useMemo(() => pages[selectedPage].metric as 'download' | 'preview' | 'impression' | 'est_reach', [pages, selectedPage]);

    const { data } = useChartData(period, metric);
    const allPeriods: Period[] = ['week', 'month'];

    const dataSource = useMemo(() => {
        switch (period) {
            case 'month':
                return data?.data.map((d, index) => {
                    return {
                        argument: formatDateToString((new Date(d.date).toDateString()), 'ddd M/DD').split(' '),
                        value: Math.round(d.count)
                    };
                });
            default:
                return data?.data.map((d) => {
                    return {
                        argument: formatDateToString((new Date(d.date).toDateString()), 'ddd MMM DD'),
                        value: Math.round(d.count)
                    };
                });
        }
    }, [data, period]);

    return (
        <div className={styles['overview-chart']}>
            <div className={styles['overview-chart--title-container']}>
                <div className={styles['overview-chart--title']}>Overview</div>
                <div className={styles['overview-chart--period']}>
                    <Dropdown<Period>
                        onClick={(option) => {
                            setPeriod(option);
                        }}
                        optionContainerClassname={styles['overview-chart--dropdown']}
                        useNewMobileStyle
                        value={period}
                        options={[
                            ...allPeriods.map((p) => ({
                                label: capitalizeFirstLetter(p),
                                value: p
                            }))
                        ]}
                    />
                </div>
            </div>
            <PageSwitcher pages={pages} selected={selectedPage} />
            <div className={styles['overview-chart__chart']}>
                <Chart seriesName={pages[selectedPage].seriesName} dataSource={dataSource} period={period} />
            </div>
        </div>
    );
}
