import useSWRInfinite from 'swr/infinite';
import { ArtistPortal } from '@bpm-web-app/api-client';
import useSWR from 'swr';
import { ArtistAlbumsSortByQueryOptions, ArtistSortByQueryOptions } from '@bpm-web-app/download-api-sdk';

export type Period = 'day' | 'week' | 'month';
export interface GetAlbumAnalyticsQuery {
    period: Period
    sort_by?: ArtistAlbumsSortByQueryOptions
    limit?: number
    skip?: number
    search?: string
}

type AlbumAnalyticsResponse = Awaited<ReturnType<typeof ArtistPortal['getAlbums']>>;

export function useInfiniteGetAlbumAnalytics(query: GetAlbumAnalyticsQuery) {
    const pageSize = query?.limit || 5;
    const { data, error, size, setSize, isValidating } = useSWRInfinite(
        (index) => `ap-get-album-${JSON.stringify([query])}-${index}`,
        (key) => {
            /* extract page index back from key name */
            const pageIndex = key.split('-').pop() || '0';
            return ArtistPortal.getAlbums(query.period, pageSize, +pageIndex * pageSize, query.sort_by, query.search);
        },
        { revalidateFirstPage: false });

    const result = data?.reduce((prev, current) => {
        let handler = prev;
        if (!handler) {
            handler = { data: current.data.slice(), pagination: current.pagination };
        } else {
            handler.data.push(...current.data.slice());
            handler.pagination = current.pagination;
        }
        return handler;
    }, undefined as undefined | AlbumAnalyticsResponse);

    const isLoadingInitialData = !result && !error;
    const isLoadingMore = result ? Math.ceil(result.data.length / pageSize) < size : false;
    const isEmpty = result?.pagination.total === 0;
    const isLastPage = isEmpty || result ? result.pagination.total <= ((result.pagination.page + 1) * result.pagination.limited) : false;

    return {
        data: result,
        isLoadingInitialData,
        isLoadingMore,
        isValidating,
        isLastPage,
        error,
        size,
        setSize
    };
}

type TopCitiesResponse = Awaited<ReturnType<typeof ArtistPortal.getTopCities>>;

export function useTopCities(period: Period, sortBy?: ArtistSortByQueryOptions, limit?: number) {
    const { data, error } = useSWR<TopCitiesResponse>(['ap-get-top-cities', { period, sortBy, limit }], () => ArtistPortal.getTopCities(period, sortBy, limit));

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

type TopCountriesResponse = Awaited<ReturnType<typeof ArtistPortal.getTopCountries>>;

export function useTopCountries(period: Period, sortBy?: ArtistSortByQueryOptions, limit?: number) {
    const { data, error } = useSWR<TopCountriesResponse>(['ap-get-top-countries', { period, sortBy, limit }], () => ArtistPortal.getTopCountries(period, sortBy, limit));

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

type WeeklyStatsResponse = Awaited<ReturnType<typeof ArtistPortal.getWeeklyStats>>;

export function useWeeklyStats() {
    const { data, error } = useSWR<WeeklyStatsResponse>(['ap-get-weekly-stats'], () => ArtistPortal.getWeeklyStats());

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

type TopSourcesResponse = Awaited<ReturnType<typeof ArtistPortal.getTopSources>>;

export function useTopSources(period: Period, sortBy?: ArtistSortByQueryOptions) {
    const { data, error } = useSWR<TopSourcesResponse>(['ap-get-top-sources', { period, sortBy }], () => ArtistPortal.getTopSources(period, sortBy));

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

type TopDJTypesResponse = Awaited<ReturnType<typeof ArtistPortal.getTopDJTypes>>;

export function useTopDJTypes(period: Period, sortBy?: ArtistSortByQueryOptions) {
    const { data, error } = useSWR<TopDJTypesResponse>(['ap-get-top-dj-types', { period, sortBy }], () => ArtistPortal.getTopDJTypes(period, sortBy));

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

type ChatDataResponse = Awaited<ReturnType<typeof ArtistPortal.getChartData>>;

export function useChartData(period: Period, type: 'download' | 'preview' | 'impression' | 'est_reach') {
    const { data, error } = useSWR<ChatDataResponse>(['ap-get-chart-data', { period, type }], () => ArtistPortal.getChartData(period, type));

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

type EngagementStatsResponse = Awaited<ReturnType<typeof ArtistPortal.getEnagementStats>>;

export function useEngagementStats(period: Period) {
    const { data, error } = useSWR<EngagementStatsResponse>(['ap-get-engagement-stats', { period }], () => ArtistPortal.getEnagementStats(period));

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

type OtherArtistsResponse = Awaited<ReturnType<typeof ArtistPortal.getOtherArtists>>;

export function useOtherArtists(period: Period) {
    const { data, error } = useSWR<OtherArtistsResponse>(['ap-get-other-artists', { period }], () => ArtistPortal.getOtherArtists(period));

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

type BannerResponse = Awaited<ReturnType<typeof ArtistPortal.getBanner>>;

export function useArtistBanner() {
    const { data, error } = useSWR<BannerResponse>(['ap-get-banner'], () => ArtistPortal.getBanner(), {
        onError: () => {

        },
    });

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export type ProfileStatusResponse = Awaited<ReturnType<typeof ArtistPortal.getProfileStatus>>;

export function useProfileStatus() {
    const { data, error, mutate } = useSWR<ProfileStatusResponse>(['ap-get-profile-status'], () => ArtistPortal.getProfileStatus());

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate
    };
}

export type GetManagingArtistResponse = Awaited<ReturnType<typeof ArtistPortal.getManagingArtist>>;

export function useManagingArtist() {
    const { data, error, mutate } = useSWR<GetManagingArtistResponse>(['ap-get-managing-artist'], () => ArtistPortal.getManagingArtist());

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate
    };
}
