import { ArtistPortal } from '@bpm-web-app/api-client';
import { createContext, useContext } from 'react';

export interface ArtistProfileStatusProperties {
    status: Awaited<ReturnType<typeof ArtistPortal.getProfileStatus>>['data']
}

export const ArtistProfileStatusContext = createContext<ArtistProfileStatusProperties>({} as never);

export const useArtistProfileStatus = () => {
    const { status } = useContext(ArtistProfileStatusContext);
    return status;
};
