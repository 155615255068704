import classNames from 'classnames';
import styles from './title-view.module.css';

interface TitleViewProps {
    title: string
    subtitle?: string;
    arrowsComponent?: React.ReactElement;
    rightComponent?: React.ReactElement;
    bottomComponent?: React.ReactElement;
}

export function TitleView({ title, subtitle, rightComponent, arrowsComponent, bottomComponent }: TitleViewProps) {
    return (
        <div className={classNames(styles['title-view'], { [styles['title-view--has-right-component']]: rightComponent })}>
            <div className={styles['title-view__left']}>
                <div className={styles['title-view--container']}>
                    <div className={styles['title-view--title']}>{title}</div>
                    {arrowsComponent}
                </div>
                <div className={styles['title-view--sub-title']}>{subtitle || ''}</div>
            </div>
            {rightComponent ? <div className={styles['title-view__right']}>{rightComponent}</div> : null}
            {bottomComponent ? <div>{bottomComponent}</div> : null}
        </div>
    );
}
