import { useUniqueArray } from '@bpm-web-app/utils';
import { Fragment, useState } from 'react';
import { CuratedSet } from '@bpm-web-app/download-api-sdk';
import { useGetAlbumFeaturedIn } from '@bpm-web-app/swr-hooks';
import GhostElement from '../shared/ghost-element/ghost-element';
import styles from './featured-in.module.css';
import { PlaylistCard } from '../shared/card/playlist-card/playlist-card';
import { CuratedSetCard } from '../shared/card/curated-set-card/curated-set-card';
import SeeMore from '../shared/see-more-button/see-more-btn';

const FEATURED_IN_LINES_WIDTH = [90, 70];

export interface FeaturedInProps {
    albumId: number;
}

export function FeaturedIn({ albumId }: FeaturedInProps) {
    const [limit, setLimit] = useState(8);
    const { data: artistFeaturedInData, isLoading: isFeaturedInDataLoading } = useGetAlbumFeaturedIn(albumId);

    const ghostLoadingItems = useUniqueArray(20);

    if (isFeaturedInDataLoading) {
        return (
            <div className="ghost-loading__loading-container">
                <h3 className={styles['featured-in__title']}>Featured In</h3>

                <div className={styles['featured-in__loading']}>
                    {ghostLoadingItems.map((uuid) => (
                        <GhostElement key={`featured-in-${uuid}`} itemClass={styles['featured-in__loading-item']} linesWidthArray={FEATURED_IN_LINES_WIDTH} />
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div className={styles['featured-in']}>
            <h3 className={styles['featured-in__title']}>Featured In</h3>
            <div className={styles['featured-in__grid']}>
                {artistFeaturedInData?.playlists.slice(0, limit).map((item: any) => (
                    <Fragment key={item.id}>
                        <PlaylistCard
                            key={item.id}
                            playlist={item}
                            playlists={artistFeaturedInData?.playlists}
                        />
                    </Fragment>
                ))}
                {artistFeaturedInData?.curated.slice(0, limit).map((item: any) => (
                    <Fragment key={item.id}>
                        <CuratedSetCard
                            key={item.id}
                            curatedSet={item as CuratedSet} />
                    </Fragment>
                ))}
            </div>
            {limit < Math.max(artistFeaturedInData?.curated.length ?? 0, artistFeaturedInData?.playlists.length ?? 0) && (
                <div className="spacing--top">
                    <SeeMore expand={false} variant="text" onClick={() => setLimit(limit + 8)} />
                </div>
            )}
        </div>
    );
}
