import { formatDateToString } from '@bpm-web-app/utils';
import styles from './artist-application-metadata.module.css';

interface ArtistApplicationMetadataProps {
    releaseDate?: Date;
    isrc?: string;
    recordLabel?: string;
    producedBy?: string;

}

const RELEASE_DATE_FORMAT = 'MM/DD/YYYY'; // 'MMMM D, YYYY';

export function ArtistApplicationMetadata({ releaseDate, isrc, recordLabel, producedBy }: ArtistApplicationMetadataProps) {
    return (
        <div className={styles['artist-application-metadata']}>
            {/* Release Date */}
            <div className={styles['artist-application-metadata--text']}>{`Release Date: ${formatDateToString(String(releaseDate), RELEASE_DATE_FORMAT)}`}</div>
            {/* ISRC */}
            <div className={styles['artist-application-metadata--text']}>{`ISRC: ${isrc}`}</div>
            {/* Record Label */}
            <div className={styles['artist-application-metadata--text']}>{`Record Label: ${recordLabel}`}</div>
            {/* Produced By */}
            <div className={styles['artist-application-metadata--text']}>{`Produced By: ${producedBy}`}</div>

        </div>
    );
}
