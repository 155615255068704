import { ReactNode, createContext, useState, useMemo, useContext } from 'react';

export interface ArtistPortalHeaderContextProperties {
    isSubmitTrackButtonHidden: boolean;
    resetSubmitTrackButton: () => void;
    hideSubmitTrackButton: () => void;

}

export const ArtistPortalHeaderContext = createContext<ArtistPortalHeaderContextProperties>({
    isSubmitTrackButtonHidden: false,
    resetSubmitTrackButton: () => { },
    hideSubmitTrackButton: () => { },
});

export interface ArtistPortalHeaderProviderProps {
    children: ReactNode;
    overrideIsSubmitTrackButtonHidden?: boolean
    initialState?: {
        isSubmitTrackButtonHidden: boolean;
    };
}

export function ArtistPortalHeaderProvider({ children, initialState, overrideIsSubmitTrackButtonHidden }: ArtistPortalHeaderProviderProps) {
    const [isSubmitTrackButtonHidden, setSubmitTrackButtonHidden] = useState<boolean>(initialState?.isSubmitTrackButtonHidden || false);

    const value = useMemo<ArtistPortalHeaderContextProperties>(
        () => ({
            isSubmitTrackButtonHidden: overrideIsSubmitTrackButtonHidden === true ? true : isSubmitTrackButtonHidden,
            resetSubmitTrackButton: () => {
                setSubmitTrackButtonHidden(false);
            },
            hideSubmitTrackButton: () => {
                setSubmitTrackButtonHidden(true);
            },
        }),
        [isSubmitTrackButtonHidden, overrideIsSubmitTrackButtonHidden]
    );

    return <ArtistPortalHeaderContext.Provider value={value}>{children}</ArtistPortalHeaderContext.Provider>;
}

export function useArtistPortalHeader() {
    const context = useContext(ArtistPortalHeaderContext);
    context.hideSubmitTrackButton();
    return context;
}
