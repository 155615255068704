import GhostElement from '../../../shared/ghost-element/ghost-element';
import styles from './analytics-card-ghost-loading.module.css';

const STATS_LINES_WIDTH = [90, 30, 50];
const LARGE_STATS_LINES_WIDTH = [90, 50];
const RIGHT_STATS_LINES_WIDTH = [60];

interface AnalyticsCardGhostLoadingProps {
    variant?: 'small' | 'large'
}

export function AnalyticsCardGhostLoading({ variant = 'small' }: AnalyticsCardGhostLoadingProps) {
    return (
        <div className={styles['ghost-loading-elements']}>
            {variant === 'large' ?
                <GhostElement itemClass={styles['ghost-loading-elements--large']} linesWidthArray={LARGE_STATS_LINES_WIDTH} />
                : (
                    <>
                        <div className={styles['ghost-loading-elements--container']}>
                            <GhostElement linesWidthArray={STATS_LINES_WIDTH} />
                        </div>

                        <div className={styles['ghost-loading-elements--mobile-container']}>
                            <div className={styles['ghost-loading-elements--mobile-container-left']}>
                                <GhostElement itemClass={styles['ghost-loading-elements--mobile-container-element']} />
                            </div>
                            <div className={styles['ghost-loading-elements--mobile-container-right']}>
                                <GhostElement itemClass={styles['ghost-loading-elements--mobile-container-element']} linesWidthArray={RIGHT_STATS_LINES_WIDTH} />
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    );
}
