import 'intersection-observer';

import 'focus-visible/dist/focus-visible.min';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

import '@bpm-web-app/components/src/styles/index.css';
import '../styles/index.css';

import {
    AppNotifications,
    AppToast,
    ArtistPortalHeader,
    NavContext,
    NavArtistPortal,
    NavProvider,
    ArtistPortalPlayer,
    PlayerProvider
} from '@bpm-web-app/components';
import { SWRConfigProvider, useProfileStatus } from '@bpm-web-app/swr-hooks';
import { HistoryProvider, HubSwitchProvider, useWindowResizing, ViewportProvider, startBugsnag, ArtistProfileStatusContext, useApiErrorHandler, UserSettingsProvider } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { AppProps } from 'next/app';
import Head from 'next/head';
import ReactTooltip from 'react-tooltip';

import { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { Loader, SessionProvider, ActionModalProvider, ThreeDotsSheetContext, ThreeDotsSheet, ContextMenu, ContextMenuContext, ContextMenuProvider } from '@bpm-web-app/components/shared';
import { ErrorMessage } from '@bpm-web-app/components/src/lib/pages';
import Script from 'next/script';
import { ArtistPortalHeaderProvider } from '@bpm-web-app/components/src/lib/supreme-header/artist-portal-header.context';
import { ArtistApplicationStatus } from '@bpm-web-app/download-api-sdk';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DraggableComponent } from '@bpm-web-app/components/src/lib/shared/draggable-component/draggable-component';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/dot-notation
const ErrorBoundary = startBugsnag(process.env['NEXT_PUBLIC_BUGSNAG_ARTIST_API_KEY']!);

export const InnerApp = ({ Component, pageProps }: AppProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const { data: profileStatusData, error } = useProfileStatus();
    const errorHandler = useApiErrorHandler();

    useEffect(() => {
        if (error) {
            errorHandler({ error });
        }
    }, [errorHandler, error]);

    useEffect(() => {
        // Analytics.initialize();
        if (profileStatusData?.data) {
            setIsLoading(false);
        }
    }, [profileStatusData]);

    const artistProfileStatus = useMemo(() => {
        if (profileStatusData) {
            return { status: profileStatusData.data };
        }
        return undefined;
    }, [profileStatusData]);

    const { isReady } = useRouter();
    if (!isReady) {
        return <Loader />;
    }

    if (isLoading || !artistProfileStatus) { return <Loader />; }

    return (
        <ArtistProfileStatusContext.Provider value={artistProfileStatus}>
            <HubSwitchProvider isArtistPlatform>
                <PlayerProvider>
                    <ContextMenuProvider>
                        <ViewportProvider>
                            <NavProvider>
                                <ArtistPortalHeaderProvider
                                    overrideIsSubmitTrackButtonHidden={artistProfileStatus.status.application_status !== ArtistApplicationStatus.Approved}>
                                    <DndProvider backend={HTML5Backend}>
                                        <DraggableComponent />
                                        <ArtistPortalHeader />
                                        <NavArtistPortal />

                                        <ContextMenuContext.Consumer>
                                            {(value) => (value.renderLocation === 'app' ? <ContextMenu /> : null)}
                                        </ContextMenuContext.Consumer>
                                        <ThreeDotsSheetContext.Consumer>
                                            {(value) => (value.actionTypeId !== -1 && value.renderLocation === 'app' ? <ThreeDotsSheet /> : null)}
                                        </ThreeDotsSheetContext.Consumer>
                                        <NavContext.Consumer>
                                            {(navCtx) => (
                                                <main
                                                    id="main"
                                                    className={classNames('main', {
                                                        'main--nav-open': navCtx.isNavOpen,
                                                        'main--nav-hidden': navCtx.isNavHidden
                                                    })}
                                                >
                                                    <Script
                                                        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
                                                        strategy="afterInteractive"
                                                    />
                                                    <Script strategy="afterInteractive">
                                                        {`window.dataLayer = window.dataLayer || [];
                                                        function gtag(){dataLayer.push(arguments);}
                                                        gtag('js', new Date());
                                                        gtag('config', '${process.env.NEXT_PUBLIC_GTM_ID}');
                                                    `.trim()}
                                                    </Script>
                                                    <Script id="facebook-analytics" strategy="afterInteractive">
                                                        {`
                                                    !function (f, b, e, v, n, t, s) {
                                                        if (f.fbq)
                                                        return;
                                                        n = f.fbq = function () {
                                                        n.callMethod ?
                                                            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                                                        };
                                                        if (!f._fbq)
                                                        f._fbq = n;
                                                        n.push = n;
                                                        n.loaded = !0;
                                                        n.version = '2.0';
                                                        n.queue = [];
                                                        t = b.createElement(e);
                                                        t.async = !0;
                                                        t.src = v;
                                                        s = b.getElementsByTagName(e)[0];
                                                        s.parentNode.insertBefore(t, s)
                                                    }(window,
                                                        document, 'script', ' https://connect.facebook.net/en_US/fbevents.js');

                                                    fbq('init', '${process.env.NEXT_PUBLIC_FB_ID}');
                                                    fbq('track', "PageView");
                                                `.trim()}
                                                    </Script>
                                                    <Script id="hotjar" strategy="afterInteractive">
                                                        {`
                                                        (function(h,o,t,j,a,r){
                                                            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                                                            h._hjSettings={hjid:3347938,hjsv:6};
                                                            a=o.getElementsByTagName('head')[0];
                                                            r=o.createElement('script');r.async=1;
                                                            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                                                            a.appendChild(r);
                                                        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                                                `.trim()}
                                                    </Script>
                                                    <ArtistPortalPlayer />
                                                    <Component {...pageProps} />
                                                </main>
                                            )}
                                        </NavContext.Consumer>
                                    </DndProvider>
                                </ArtistPortalHeaderProvider>
                            </NavProvider>
                        </ViewportProvider>
                    </ContextMenuProvider>
                    <ReactTooltip
                        place="top"
                        effect="solid"
                        delayShow={400}
                    />
                    <AppToast />
                    <AppNotifications />
                </PlayerProvider>
            </HubSwitchProvider>
        </ArtistProfileStatusContext.Provider>
    );
};

function App(props: AppProps) {
    useWindowResizing(
        () => {
            document.body.classList.add('isResizing');
        },
        () => {
            document.body.classList.remove('isResizing');
        },
        []
    );

    useEffect(() => {
        document.body.classList.add('is-artist-platform');
    }, []);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        Modal.setAppElement(document.getElementById('__next')!);
    }, []);

    return (
        <ErrorBoundary FallbackComponent={ErrorMessage}>
            <Head>
                <title>BPM Artist Platform</title>
                <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#E6EFE9" />
                <meta name="msapplication-TileColor" content="#E6EFE9" />
                <meta name="theme-color" content="#121212" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <script type="text/javascript" async src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Ynf4t6" />
            </Head>
            <HistoryProvider>
                <ActionModalProvider>
                    <UserSettingsProvider>
                        <SWRConfigProvider isArtistPortal>
                            <SessionProvider>
                                <InnerApp {...props} />
                            </SessionProvider>
                        </SWRConfigProvider>
                    </UserSettingsProvider>
                </ActionModalProvider>
            </HistoryProvider>
        </ErrorBoundary>

    );
}

export default dynamic(() => Promise.resolve(App), { ssr: false });
