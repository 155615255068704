import { ArtistAlbumsSortByQueryOptions, ArtistSortByQueryOptions } from '@bpm-web-app/download-api-sdk';
import { Period, useTopCities, useTopCountries } from '@bpm-web-app/swr-hooks';
import { capitalizeFirstLetter } from '@bpm-web-app/utils';
import { useMemo, useState } from 'react';
import ArtistPortalList from '../../../artist-portal-list/artist-portal-list';
import { ArtistPortalListPreset } from '../../../artist-portal-list/artist-portal-list-helpers';
import Dropdown from '../../../dropdown/dropdown';
import SeeMore from '../../../shared/see-more-button/see-more-btn';
import { SortOption } from '../../../sort-options-sheet/sort-options-sheet';
import { TitleView } from '../title-view/title-view';
import styles from './top-locations.module.css';
import { GhostComponent } from '../../../shared';

function getFlagEmoji(countryCode: string) {
    const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map((char) => 127397 + char.charCodeAt(0));
    return String.fromCodePoint(...codePoints);
}

export function ArtistTopLocations() {
    const [cityPeriod, setCityPeriod] = useState<Period>('week');
    const [sortCityType, setSortCityType] = useState<ArtistAlbumsSortByQueryOptions | ArtistSortByQueryOptions>(ArtistSortByQueryOptions.Downloads);

    const [countryPeriod, setCountryPeriod] = useState<Period>('week');
    const [sortCountryType, setSortCountryType] = useState<ArtistAlbumsSortByQueryOptions | ArtistSortByQueryOptions>(ArtistSortByQueryOptions.Downloads);

    const [limit, setLimit] = useState(5);

    const handleSeeMore = () => {
        setLimit(limit + 5);
    };

    const handleSeeLess = () => {
        setLimit(limit >= 10 ? limit - 5 : 5);
    };

    const { data: cityData } = useTopCities(cityPeriod, sortCityType as ArtistSortByQueryOptions, limit);
    const { data: countryData } = useTopCountries(countryPeriod, sortCountryType as ArtistSortByQueryOptions, limit);

    const locationSortOption: SortOption<ArtistSortByQueryOptions>[] = [
        { actionType: ArtistSortByQueryOptions.Downloads, label: 'Downloads' },
        { actionType: ArtistSortByQueryOptions.Previews, label: 'Previews' },
    ];

    const allPeriods: Period[] = ['day', 'week', 'month'];

    const titleViewCities = <TitleView
        title="Top Cities"
        rightComponent={<Dropdown<Period>
            onClick={(option) => {
                setCityPeriod(option);
            }}
            value={cityPeriod}
            useNewMobileStyle
            options={[
                ...allPeriods.map((p) => ({
                    label: capitalizeFirstLetter(p),
                    value: p
                }))
            ]}
        />} />;

    const titleViewCountries = <TitleView
        title="Top Countries"
        rightComponent={<Dropdown<Period>
            onClick={(option) => {
                setCountryPeriod(option);
            }}
            value={countryPeriod}
            useNewMobileStyle
            options={[
                ...allPeriods.map((p) => ({
                    label: capitalizeFirstLetter(p),
                    value: p
                }))
            ]}
        />} />;

    const topCityData = useMemo(() => {
        return cityData?.data?.map((city) => {
            return {
                source: city.city,
                ...city,
                icon: city.country !== 'Unknown' ? getFlagEmoji(city.country) : '？'
            };
        }) || [];
    }, [cityData]);

    const topCountryData = useMemo(() => {
        return countryData?.data?.map((country) => {
            if (country.country === 'Unknown') {
                return {
                    ...country,
                    source: 'Unknown',
                    icon: '？'
                };
            }
            return {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                source: new Intl.DisplayNames(['en'], { type: 'region' }).of(country.country),
                ...country,
                icon: getFlagEmoji(country.country)
            };
        }) || [];
    }, [countryData]);

    return (
        <>
            <div className={styles['top-locations']}>
                <div>
                    {titleViewCities}
                    {cityData ? <ArtistPortalList
                        sortType={sortCityType}
                        setSortType={setSortCityType}
                        list={topCityData}
                        sortOptions={locationSortOption}
                        preset={ArtistPortalListPreset.Location} /> : <GhostComponent type="track-list" elementsCount={5} isArtistPortal preset={ArtistPortalListPreset.Location} />}
                </div>
                <div>
                    {titleViewCountries}
                    {countryData ? <ArtistPortalList
                        sortType={sortCountryType}
                        setSortType={setSortCountryType}
                        list={topCountryData}
                        sortOptions={locationSortOption}
                        preset={ArtistPortalListPreset.Location} /> : <GhostComponent type="track-list" elementsCount={5} isArtistPortal preset={ArtistPortalListPreset.Location} />}
                </div>
            </div>
            <SeeMore expand={false} variant="text" onClick={handleSeeMore} onSeeLess={handleSeeLess} seeLess={limit >= 6} />
        </>
    );
}
