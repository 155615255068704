import { useEffect } from 'react';
import { useRouter } from 'next/router';
import ArtistPortalBanner from '../../../artist-portal-banner/artist-portal-banner';
import ArtistPortalHeader from '../../../artist-portal-header/artist-portal-header';
import BreakpointView from '../../../shared/ui/breakpoint-view/breakpoint-view';
import { AnalyticEngagement } from '../analytic-engagement/analytic-engagement';
import { AnalyticsPreview } from '../analytic-preview/analytics-preview';
import { ArtistOtherArtists } from '../other-artists/other-artists';
import { ArtistOverviewChart } from '../overview-chart/overview-chart';
import { ArtistTopLocations } from '../top-locations/top-locations';
import { ArtistTopSources } from '../top-sources/top-sources';
import { ArtistTopWeeklyTracks } from '../top-weekly-tracks/top-weekly-tracks';
import { ArtistYourTracks } from '../your-tracks/your-tracks';
import styles from './dashboard.module.css';

export function ArtistDashboard() {
    const router = useRouter();

    useEffect(() => {
        const headings = document.querySelectorAll('#dashboard-container section');
        const onScroll = () => {
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < headings.length; index++) {
                const ha = headings[index];
                const rect = ha.getBoundingClientRect();
                if (rect.top > 0 && rect.top < 150) {
                    const location = router.pathname;
                    if (window.location.hash !== `#${ha.id}`) {
                        window.history.replaceState(null, '', `${location}#${ha.id}`);
                        const artistEvent = new CustomEvent('artist-nav-update', {
                            bubbles: true
                        });
                        document.dispatchEvent(artistEvent);
                    }
                    break;
                }
            }
        };
        onScroll();
        document.addEventListener('scroll', onScroll);
        return () => {
            document.removeEventListener('scroll', onScroll);
        };
    }, [router]);

    return (
        <div className={styles['dashboard__container']} id="dashboard-container">
            <section id="home">
                <ArtistPortalHeader />
                <div className={styles['dashboard__container__top-section']}>
                    <div className={styles['dashboard__container__top-section--left']}>
                        <AnalyticsPreview />
                        <BreakpointView
                            mobileChildren={null}
                            desktopChildren={<ArtistOverviewChart />} />
                    </div>
                    <div className={styles['dashboard__container__top-section--right']}>
                        <ArtistTopWeeklyTracks />
                        <ArtistPortalBanner />
                    </div>
                </div>
            </section>
            <section id="your-tracks">
                <ArtistYourTracks />
            </section>
            <section id="locations">
                <ArtistTopLocations />
            </section>
            <section id="engagement">
                <AnalyticEngagement />
            </section>
            <section id="artists">
                <ArtistOtherArtists />
            </section>
            <section id="sources">
                <ArtistTopSources />
            </section>
        </div>
    );
}
