import { apiKey, ArtistPortal } from '@bpm-web-app/api-client';
import { ActionModal } from '@bpm-web-app/components/src/lib/shared/action-modal/action-modal';
import { LoadingIcon } from '@bpm-web-app/components/src/lib/shared/loading-icon/loading-icon';
import { errorToString, showToast } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';
import { useManagingArtist } from '@bpm-web-app/swr-hooks';
import { ApplicationHeader } from '../artist-application-header/artist-application-header';
import { fileInputsToDictionary, SubmitMusic, TrackInfoInputs, UploadFilesInputs } from '../submit-music/submit-music';
import styles from './submit-music-form.module.css';

export function SubmitMusicForm() {
    const router = useRouter();
    const [trackInfoData, setTrackInfoData] = useState<TrackInfoInputs>();
    const [uploadFilesData, setUploadFilesData] = useState<UploadFilesInputs>();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [hasCompletedTrackInfo, setHasCompletedTrackInfo] = useState(false);
    const [hasCompletedUploadFiles, setHasCompletedUploadFiles] = useState(false);

    const { data: artistData } = useManagingArtist();
    const artistName = useMemo(() => artistData?.data.name, [artistData]);

    const handleSubmit = useCallback((trackInfo: TrackInfoInputs, uploadFiles: UploadFilesInputs) => {
        if (trackInfo && uploadFiles) {
            setIsSubmitting(true);
            const filesToUpload: { [key: string]: File } = fileInputsToDictionary(trackInfo, uploadFiles);
            Promise.all(Object.keys(filesToUpload).map((key) => {
                return ArtistPortal.uploadAudioFile(filesToUpload[key]).then((path) => {
                    return {
                        version_name: key,
                        track_path: path
                    };
                });
            })).then((tracks) => {
                const formData = new FormData();
                formData.append('track_artwork', uploadFiles.trackArtwork[0], uploadFiles.trackArtwork[0].name);
                Object.keys(trackInfo).forEach((_key) => {
                    const key = _key as keyof TrackInfoInputs;
                    const value = trackInfo[key];
                    formData.append(key, typeof value === 'string' ? value : JSON.stringify(value));
                });
                formData.append('tracks', JSON.stringify(tracks));
                // eslint-disable-next-line @typescript-eslint/dot-notation
                const key = apiKey();
                const headers = {} as { [key: string]: string };
                if (key) {
                    // eslint-disable-next-line @typescript-eslint/dot-notation
                    headers['Authorization'] = key;
                }
                // eslint-disable-next-line @typescript-eslint/dot-notation
                return fetch(`${process.env['NEXT_PUBLIC_DOWNLOAD_API_BASE_PATH']}/artist-portal/track/submit`, {
                    method: 'POST',
                    credentials: 'include',
                    body: formData,
                    headers
                });
            }).then(async (data) => {
                if (data.ok) {
                    setIsSubmitting(false);
                    router.replace('/');
                } else {
                    const errorData = await data.json();
                    throw errorData;
                }

                return null;
            }).catch((err) => {
                setIsSubmitting(false);
                showToast({ type: 'error', title: errorToString(err) });
            });
        }
    }, [router]);

    return (
        <div className={styles['submit-music-form']}>
            <div className={styles['submit-music-form--container']}>
                <ApplicationHeader title="Submit Track" subtitle="Get your music played by pro DJs worldwide through BPM Supreme." />
                <SubmitMusic
                    trackInfoData={trackInfoData || undefined}
                    setTrackInfoData={setTrackInfoData}
                    uploadFilesData={uploadFilesData || undefined}
                    setUploadFilesData={(data) => {
                        setUploadFilesData(data);
                        handleSubmit(trackInfoData, data);
                    }}
                    handleBack={router.back}
                    isLoading={isSubmitting}
                    hasCompletedTrackInfo={hasCompletedTrackInfo}
                    hasCompletedUploadFiles={hasCompletedUploadFiles}
                    setHasCompletedTrackInfo={setHasCompletedTrackInfo}
                    setHasCompletedUploadFiles={setHasCompletedUploadFiles}
                    artistName={artistName}
                />
            </div>
            <ActionModal
                headerTitle={<div className={styles['submit-music-form__progress-popup-title']}><LoadingIcon />Submitting Track</div>}
                subtitle="Your track is uploading."
                hideCancel
                variant="dark"
                isOpen={isSubmitting}
            />
        </div>
    );
}
