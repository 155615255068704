import { useWeeklyStats } from '@bpm-web-app/swr-hooks';
import { useMemo } from 'react';
import styles from './analytics-preview.module.css';
import { AnalyticsCard } from '../analytics-card/analytics-card';
import { AnalyticsCardGhostLoading } from '../analytics-card/analytics-card-ghost-loading';

export function AnalyticsPreview() {
    const { data } = useWeeklyStats();
    const isLoading = useMemo(() => {
        if (data === undefined || data === null) {
            return true;
        }
        return false;
    }, [data]);

    const weeklyData = useMemo(() => data?.data, [data]);

    return (
        <div className={styles['analytics-preview']}>
            <div className={styles['analytics-preview--card-container']}>
                {isLoading ?
                    (<AnalyticsCardGhostLoading />) :
                    (<AnalyticsCard title="Weekly Previews" tooltip="Your total number of plays this week." value={weeklyData.previews} percent_changed={weeklyData.previews_percentage * -1} />)}
                <div className={styles['analytics-preview--separator']} />

                {isLoading ?
                    (<AnalyticsCardGhostLoading />) :
                    (<AnalyticsCard title="Weekly Downloads" tooltip="Your total number of downloads this week." value={weeklyData.downloads} percent_changed={weeklyData.downloads_percentage} />)}
                <div className={styles['analytics-preview--separator']} />

                {isLoading ?
                    (<AnalyticsCardGhostLoading />) :
                    (<AnalyticsCard title="Weekly Est. Reach" tooltip="Your weekly estimated audience based on DJs who downloaded your music." value={weeklyData.est_reach} percent_changed={weeklyData.est_reach_percentage} />)}
            </div>
        </div>
    );
}
