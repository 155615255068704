import { ReactElement, useMemo, useState } from 'react';
import PageSwitcher from '../../../page-switcher/page-switcher';
import styles from './artist-detail-sections.module.css';

interface ArtistDetailSectionsProps {
    musicComponent?: ReactElement;
    artistInfoComponent: ReactElement;
}

export function ArtistDetailSections({ musicComponent, artistInfoComponent }: ArtistDetailSectionsProps) {
    const [selectedPage, setSelectedPage] = useState<number>(musicComponent ? 0 : 1);

    const pagesData = useMemo(() => [
        {
            text: 'Music',
            enabled: !!musicComponent,
            onSelected: (index: number) => {
                setSelectedPage(index);
            }
        },
        {
            text: 'Artist Info',
            enabled: true,
            onSelected: (index: number) => {
                setSelectedPage(index);
            }
        },
    ], [musicComponent]);

    const currentPage = useMemo(() => {
        switch (selectedPage) {
            case 0:
                return musicComponent;
            case 1:
                return artistInfoComponent;
            default:
                return null;
        }
    }, [artistInfoComponent, musicComponent, selectedPage]);

    return (
        <div className={styles['artist-detail-sections']}>
            <div className={styles['artist-detail-sections--padding']}>
                <PageSwitcher pages={pagesData} selected={selectedPage} />
                <div className={styles['artist-detail-sections--divider']} />
            </div>
            {currentPage}
        </div>
    );
}
