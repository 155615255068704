import { useInfiniteGetAlbumAnalytics } from '@bpm-web-app/swr-hooks';
import classnames from 'classnames';
import { ArtistAlbumsSortByQueryOptions } from '@bpm-web-app/download-api-sdk';
import ArtistPortalList from '../../../artist-portal-list/artist-portal-list';
import { ArtistPortalListPreset } from '../../../artist-portal-list/artist-portal-list-helpers';
import { AppLink, GhostComponent } from '../../../shared';
import styles from './top-weekly-tracks.module.css';
import BPMIcons from '../../../shared/bpm-icons/bpm-icons';
import { Divider } from '../../../divider/divider';

export function ArtistTopWeeklyTracks() {
    const { data } = useInfiniteGetAlbumAnalytics({ period: 'week', limit: 3, sort_by: ArtistAlbumsSortByQueryOptions.Downloads });

    const titleView = (
        <div>
            <div className={styles['top-weekly-tracks--title-container']}>
                <div className={styles['top-weekly-tracks--title']}>Top Weekly Tracks</div>
                <div className={styles['top-weekly-tracks--sub-title']}>By Downloads</div>
            </div>
            <Divider />
        </div>);

    if (!data) {
        return (
            <div className={styles['top-weekly-tracks']}>
                {titleView}
                <GhostComponent type="track-list" elementsCount={3} isArtistPortal preset={ArtistPortalListPreset.AlbumAnalyticsSmall} />
            </div>
        );
    }

    return (
        <div className={styles['top-weekly-tracks']}>
            {titleView}
            <ArtistPortalList list={data.data} preset={ArtistPortalListPreset.AlbumAnalyticsSmall} />
            <AppLink href="#your-tracks">
                <a>
                    <span className={classnames(styles['top-weekly-tracks__your-tracks'], 'link-hover')}>
                        Go to Your Tracks <BPMIcons.ArrowRight />
                    </span>
                </a>
            </AppLink>
        </div>
    );
}
