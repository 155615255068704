import styles from './artist-portal-banner-ghost-loading.module.css';
import GhostElement from '../shared/ghost-element/ghost-element';

export function ArtistPortalBannerGhostLoading() {
    return (
        <div className={styles['ghost-loading-elements']}>
            <div className={styles['ghost-loading-elements--container']}>
                <GhostElement itemClass={styles['ghost-loading-elements--top']} linesWidthArray={[20]} isRow />
                <GhostElement itemClass={styles['ghost-loading-elements--top']} linesWidthArray={[180, 80]} lineHeight={24} isRow />
            </div>

            <div className={styles['ghost-loading-elements--footer']}>
                <GhostElement itemClass={styles['ghost-loading-elements--button']} isRow linesWidthArray={[40]} lineHeight={36} />
            </div>
        </div>
    );
}

export default ArtistPortalBannerGhostLoading;
