import { ArtistAlbumsSortByQueryOptions, ArtistSortByQueryOptions } from '@bpm-web-app/download-api-sdk';
import { Period, useInfiniteGetAlbumAnalytics } from '@bpm-web-app/swr-hooks';
import { capitalizeFirstLetter, convertToPluralIfNeeded, useDebounce, useViewport } from '@bpm-web-app/utils';
import { useCallback, useState } from 'react';
import classNames from 'classnames';
import ArtistPortalList from '../../../artist-portal-list/artist-portal-list';
import { ArtistPortalListPreset } from '../../../artist-portal-list/artist-portal-list-helpers';
import Dropdown from '../../../dropdown/dropdown';
import { GhostComponent, NoResultsBlock, SearchInput } from '../../../shared';
import SeeMore from '../../../shared/see-more-button/see-more-btn';
import BreakpointView from '../../../shared/ui/breakpoint-view/breakpoint-view';
import { SortOption } from '../../../sort-options-sheet/sort-options-sheet';
import { TitleView } from '../title-view/title-view';
import styles from './your-tracks.module.css';

export function ArtistYourTracks() {
    const [period, setPeriod] = useState<Period>('week');
    const [sortType, setSortType] = useState<ArtistAlbumsSortByQueryOptions | ArtistSortByQueryOptions>(ArtistAlbumsSortByQueryOptions.ReleaseDate);
    const { isMobile } = useViewport();
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 300);
    const query = { period, sort_by: sortType as ArtistAlbumsSortByQueryOptions, search: debouncedSearchTerm };
    const { data, isLastPage, isLoadingMore, size, setSize } = useInfiniteGetAlbumAnalytics(query);

    const tracksSortOption: SortOption<ArtistAlbumsSortByQueryOptions>[] = [
        { actionType: ArtistAlbumsSortByQueryOptions.ReleaseDate, label: 'Release Date' },
        { actionType: ArtistAlbumsSortByQueryOptions.Downloads, label: 'Downloads' },
        { actionType: ArtistAlbumsSortByQueryOptions.Previews, label: 'Previews' },
        { actionType: ArtistAlbumsSortByQueryOptions.Impressions, label: 'Impressions' },
    ];

    const loadMoreComments = useCallback(() => {
        if (isLoadingMore) return;
        setSize(size + 1);
    }, [isLoadingMore, setSize, size]);

    const loadLessComments = useCallback(() => {
        if (isLoadingMore) return;
        if (size !== 1) {
            setSize(size - 1);
        }
    }, [isLoadingMore, setSize, size]);

    const allPeriods: Period[] = ['day', 'week', 'month'];

    const tracksData = useCallback(() => {
        if (data) {
            return data.data;
        }
        return [];
    }, [data]);

    const titleView = <TitleView
        title={isMobile ? 'Your Tracks' : convertToPluralIfNeeded(data?.pagination?.total || 0, 'Your Track', 'Your Tracks', true)}
        subtitle="View all of your tracks that are currently live on BPM Supreme."
        bottomComponent={
            <div className={styles['your-tracks__subheader-container']}>
                <div className={styles['your-tracks__search-container']}>
                    <SearchInput
                        placeholder="Search Your Tracks"
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                        }}
                        value={searchTerm}
                        onClear={(e) => {
                            setSearchTerm('');
                        }}
                    />
                </div>
                <div>
                    <Dropdown<Period>
                        onClick={(option) => {
                            setPeriod(option);
                        }}
                        useNewMobileStyle
                        value={period}
                        options={[
                            ...allPeriods.map((p) => ({
                                label: capitalizeFirstLetter(p),
                                value: p
                            }))
                        ]}
                    />
                </div>
            </div>
        } />;

    return (
        <div>
            {titleView}
            {data
                ? (
                    <>
                        {
                            tracksData().length === 0
                                ? <NoResultsBlock>{`No results found for "${debouncedSearchTerm}"`}</NoResultsBlock>
                                : <ArtistPortalList list={tracksData()} preset={ArtistPortalListPreset.AlbumAnalytics} setSortType={setSortType} sortType={sortType} sortOptions={tracksSortOption} />
                        }
                        {!isLastPage && tracksData().length !== 0 ? (
                            <BreakpointView
                                mobileChildren={<SeeMore disabled={isLoadingMore} onClick={loadMoreComments} />}
                                desktopChildren={
                                    <div className={styles['your-tracks__see-more-section']}>
                                        <SeeMore expand={false} variant="text" onClick={() => { loadMoreComments(); }} onSeeLess={loadLessComments} seeLess={size > 1} />
                                    </div>
                                } />

                        ) : null}
                    </>
                ) : <GhostComponent type="track-list" elementsCount={5} isArtistPortal preset={ArtistPortalListPreset.AlbumAnalytics} />}
        </div>
    );
}
