import { User } from '@bpm-web-app/supreme-api-sdk';
import {
    HLSPlayer,
    PLAYER_PREVIEW_SECONDS,
    PlayerState,
    State as PlayerStateEnum,
    isServerSide,
    showToast,
    useHubSwitch
} from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FiltersContext } from '../filters/filters.context';
import { MaxiPlayer } from '../maxi-player/maxi-player';
import { ArtistMiniPlayer } from '../mini-player/artist-mini-player';
import { NavContext } from '../nav/nav.context';
import { useSession } from '../shared/session-provider/session-provider';
import { VersionsMobileDrawer } from '../versions-mobile-drawer';
import { usePlayer, usePlayerDuration } from '../player-context';
import styles from './player.module.css';

/**
 * Only used for the Artist Portal
 */
export function ArtistPortalPlayer() {
    const {
        isOpen,
        volume,
        hasNextTrack,
        onTrackEnd,
        setElapsed,
        setStartOffset,
        isMaxiPlayer,
        currentTrack,
        useSdk,
        nextTrack,
        isPreviewTrack,
        currentDuration,
    } = usePlayer();

    const { elapsed } = usePlayerDuration();

    const { isNavOpen } = useContext(NavContext);
    const { isFiltersOpen } = useContext(FiltersContext);
    const { isDownload } = useHubSwitch();
    const [isVersionMenuOpen, setIsVersionMenuOpen] = useState(false);
    const [playerState, setPlayerState] = useState<PlayerStateEnum>(PlayerState.STATE);
    const { userMemberships, platformUser } = useSession();

    useEffect(() => {
        PlayerState.onPlayerStateChange(setPlayerState);
        PlayerState.onProgress(setElapsed);
        PlayerState.onStartOffset(setStartOffset);
        return () => {
            PlayerState.offPlayerStateChange(setPlayerState);
            PlayerState.offProgress(setElapsed);
            PlayerState.offStartOffset(setStartOffset);
        };
    }, [setElapsed, setStartOffset]);

    useEffect(() => {
        if (playerState === PlayerStateEnum.Ended && hasNextTrack) onTrackEnd();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerState]);

    const streamUrl = useMemo<string>(() => {
        const type = currentTrack?.stream_info?.audioInfo?.type;
        const isHQ = true;
        const quality = isHQ ? 'hq' : 'lq';
        return currentTrack?.stream_info?.audioInfo?.[quality][type];
    }, [currentTrack]);

    useEffect(() => {
        if (!currentTrack) return;
        if (playerState === PlayerStateEnum.Playing) {
            HLSPlayer.stop();
        }

        HLSPlayer.load(streamUrl);
        HLSPlayer.play();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTrack, useSdk]);

    useEffect(() => {
        if (!currentTrack) return;
        HLSPlayer.setVolume(volume / 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [volume]);

    useEffect(() => {
        if (!isPreviewTrack) return;

        if (elapsed >= (currentDuration || PLAYER_PREVIEW_SECONDS)) {
            const freeListenerMessage = 'Keep listening. Subscribe now.';
            const activeSubscriberMessage = 'Preview limit reached. Download for full version.';
            const submitMembershipStatusMessage = 'Get full access. Request a membership now.';

            const hasSubscription = isDownload ? userMemberships.download?.has_membership : userMemberships.stream?.has_membership;

            let message = hasSubscription ? activeSubscriberMessage : freeListenerMessage;

            if (isDownload && platformUser.download?.verification_status !== User.VerificationStatusEnum.Verified) {
                message = submitMembershipStatusMessage;
            }
            showToast({ type: 'error', title: message, buttonText: hasSubscription ? undefined : 'View Plans', replaceRouteOnButtonClick: hasSubscription ? undefined : '/account/plan' });

            nextTrack();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elapsed, currentDuration]);

    if (isServerSide()) return null;

    return (
        <>
            {/* eslint-disable-next-line @typescript-eslint/dot-notation */}
            <div className={classNames(styles['player'], {
                [styles['player--open']]: isOpen,
                [styles['player--nav-open']]: isNavOpen,
                [styles['player--filters-open']]: isFiltersOpen,
                [styles['player--maxi-player']]: isMaxiPlayer,
                [styles['player--artist']]: true,
            })}
            >
                {!!currentTrack && isMaxiPlayer && <MaxiPlayer playerState={playerState} setIsVersionMenuOpen={setIsVersionMenuOpen} />}
                {!!currentTrack && !isMaxiPlayer && <ArtistMiniPlayer playerState={playerState} />}
            </div>
            <VersionsMobileDrawer isDownload={isDownload} isOpen={isVersionMenuOpen} onClose={() => setIsVersionMenuOpen(false)} />
        </>
    );
}
