import { apiKey } from '@bpm-web-app/api-client';
import { FileUploader } from '@bpm-web-app/components/src/lib/shared/file-upload/file-uploader';
import { errorToString, showToast } from '@bpm-web-app/utils';
import { joiResolver } from '@hookform/resolvers/joi';
import classNames from 'classnames';
import Joi from 'joi';
import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldError, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useManagingArtist } from '@bpm-web-app/swr-hooks';
import { ArtistApplicationButton } from '../../artist-application/artist-application-button/artist-application-button';
import { ArtistApplicationInputField } from '../../artist-application/artist-application-inputfield/artist-application-inputfield';
import { ApplicationSectionTitle } from '../../artist-application/artist-application-text/artist-application-text';
import styles from './manage-profile-form.module.css';

export interface ProfileEditInputs {
    artistName?: string;
    profilePhoto?: FileList;
    bio?: string;
    social_links?: string[];
}

interface ManageProfileFormProps {
    profileData: ProfileEditInputs;
    setProfileData: (data: ProfileEditInputs) => void;
    profileFormRef?: MutableRefObject<HTMLFormElement>;
    setArtistName: (name: string) => void;
    setArtistImage: (file: File) => void;
    setBio: (text: string) => void;
    setMediaLinks: (stringArray: string[]) => void;
}

export function ManageProfileForm({ profileData, profileFormRef, setProfileData, setArtistName, setArtistImage, setBio, setMediaLinks }: ManageProfileFormProps) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { mutate } = useManagingArtist();

    // Change Form
    const schemaArtistChanges = Joi.object({
        artistName: Joi.optional(),
        profilePhoto: Joi.any().optional(),
        bio: Joi.string().optional().allow(null, ''),
        social_links: Joi.array().min(1).custom((value, helper) => {
            if (Array.isArray(value) && value.length > 0) {
                if (value[0].trim() !== '') {
                    return value;
                }
            }
            return helper.error('array.min');
        }).messages({ 'array.min': 'At least one social media link is required.' })
    });

    const {
        register: registerProfileForm,
        handleSubmit: handleSubmitProfileForm,
        formState: { errors: errorsProfileForm },
        setValue: setValueProfileForm,
        getValues,
    } = useForm<ProfileEditInputs>({
        defaultValues: useMemo(
            () => ({
                artistName: profileData && profileData.artistName ? profileData.artistName : '',
                bio: profileData && profileData.bio ? profileData.bio : '',
                social_links: profileData && profileData.social_links ? profileData.social_links : []
            }),
            [profileData]
        ),
        resolver: joiResolver(schemaArtistChanges),
    });

    useEffect(() => {
        Object.keys(errorsProfileForm).forEach((err) => {
            showToast({ type: 'error', message: (errorsProfileForm as any)[err].message || 'An error has occurred. Please try again.' });
        });
        if (Object.keys(errorsProfileForm).length === 0) {
            toast.dismiss();
        }
    }, [errorsProfileForm]);

    const handleProfileForm: SubmitHandler<ProfileEditInputs> = useCallback((data) => {
        const formData = new FormData();
        setIsSubmitting(true);
        if (data.profilePhoto && data.profilePhoto.length) {
            formData.append('artwork', data.profilePhoto[0], data.profilePhoto[0].name);
        }
        if (data.social_links && data.social_links.length) {
            formData.append('social_media', JSON.stringify(data.social_links));
        }
        if (data.bio && data.bio.length) {
            formData.append('description', data.bio);
        }
        if (data.artistName && data.artistName.length) {
            // formData.append('name', data.artistName);
        }
        // eslint-disable-next-line @typescript-eslint/dot-notation
        const key = apiKey();
        const headers = {} as { [key: string]: string };
        if (key) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            headers['Authorization'] = key;
        }
        // eslint-disable-next-line @typescript-eslint/dot-notation
        return fetch(`${process.env['NEXT_PUBLIC_DOWNLOAD_API_BASE_PATH']}/artist-portal/profile/change-request`, {
            method: 'POST',
            credentials: 'include',
            body: formData,
            headers
        }).then(async (response) => {
            if (response.ok) {
                setIsSubmitting(false);
                mutate();
            } else {
                const errorData = await response.json();
                throw errorData;
            }

            setProfileData({
                artistName: data.artistName,
                profilePhoto: data.profilePhoto,
                bio: data.bio,
                social_links: data.social_links
            });
            return null;
        }).catch((err) => {
            setIsSubmitting(false);
            showToast({ type: 'error', title: errorToString(err) });
        });
    }, [setProfileData, mutate]);

    const profileEdit = useMemo(() => {
        return (
            <form
                id="profileEdit"
                key="profileEdit"
                onSubmit={handleSubmitProfileForm(handleProfileForm)}
                ref={profileFormRef}
                className={classNames(styles['manage-profile-form'])}>
                <div className={classNames(styles['manage-profile-form--spacer'])} />
                <ApplicationSectionTitle title="Edit Your Profile" noMargin />
                <ArtistApplicationInputField
                    placeholder="Your Artist Name"
                    inputProps={{ ...registerProfileForm('artistName', { required: false }), disabled: true }}
                    setText={setArtistName}
                    errorMessage={errorsProfileForm && errorsProfileForm.artistName ? errorsProfileForm.artistName.message : undefined}
                />

                <ApplicationSectionTitle title="Your Profile Photo" />
                <FileUploader
                    key="artwork"
                    hint="Please provide a clear photo of your face(s). 5MB File Limit."
                    accept="image"
                    filename={profileData && profileData.profilePhoto && profileData.profilePhoto.length ? profileData.profilePhoto[0].name : undefined}
                    inputProps={{ ...registerProfileForm('profilePhoto', { required: false }) }}
                    setValue={(files) => {
                        setValueProfileForm('profilePhoto', files);
                    }}
                    setPreviewFile={setArtistImage}
                    errorMessage={errorsProfileForm && errorsProfileForm.profilePhoto ? errorsProfileForm.profilePhoto.message : null}
                />
                <div className={classNames(styles['manage-profile-form--spacer'])} />
                <ArtistApplicationInputField
                    placeholder="Your Artist Bio (optional)"
                    multiline
                    inputProps={{ ...registerProfileForm('bio', { required: false }) }}
                    setText={setBio}
                    errorMessage={errorsProfileForm && errorsProfileForm.bio ? errorsProfileForm.bio.message : undefined}
                />

                <ApplicationSectionTitle title="Your Links" />
                <ArtistApplicationInputField
                    placeholder="Link to your primary social media"
                    inputProps={{ ...registerProfileForm('social_links.0', { required: false }) }}
                    isLink
                    setText={(text) => {
                        const tempMediaLinks = [text];
                        if (getValues('social_links.1')) {
                            tempMediaLinks.push(getValues('social_links.1'));
                            if (getValues('social_links.2')) {
                                tempMediaLinks.push(getValues('social_links.2'));
                            }
                        }
                        setMediaLinks(tempMediaLinks);
                    }}
                    errorMessage={errorsProfileForm && errorsProfileForm.social_links ? (errorsProfileForm.social_links as unknown as FieldError).message : undefined}
                />
                <ArtistApplicationInputField
                    placeholder="Link to additional primary social media (optional)"
                    inputProps={{ ...registerProfileForm('social_links.1', { required: false }) }}
                    setText={(text) => {
                        const tempMediaLinks = [];
                        if (getValues('social_links.0')) {
                            tempMediaLinks.push(getValues('social_links.0'));
                            tempMediaLinks.push(text);
                            if (getValues('social_links.2')) {
                                tempMediaLinks.push(getValues('social_links.2'));
                            }
                        }
                        setMediaLinks(tempMediaLinks);
                    }}
                    isLink
                />
                <ArtistApplicationInputField
                    placeholder="Link to your music (Spotify, Apple Music etc)."
                    inputProps={{ ...registerProfileForm('social_links.2', { required: false }) }}
                    setText={(text) => {
                        const tempMediaLinks = [];
                        if (getValues('social_links.0')) {
                            tempMediaLinks.push(getValues('social_links.0'));
                            if (getValues('social_links.1')) {
                                tempMediaLinks.push(getValues('social_links.1'));
                                tempMediaLinks.push(text);
                            }
                        }
                        setMediaLinks(tempMediaLinks);
                    }}
                    isLink
                />

                <ArtistApplicationButton
                    text="Submit Edits"
                    disabled={isSubmitting}
                    onContinue={handleSubmitProfileForm(handleProfileForm)}
                    isLoading={isSubmitting}
                />
            </form>
        );
    }, [handleSubmitProfileForm,
        handleProfileForm,
        profileFormRef,
        registerProfileForm,
        setArtistName,
        errorsProfileForm,
        profileData,
        setBio,
        isSubmitting,
        setArtistImage,
        setValueProfileForm,
        getValues,
        setMediaLinks]);

    return (
        <div>
            {profileEdit}
        </div>
    );
}
