import { ArtistAlbumsSortByQueryOptions, ArtistSortByQueryOptions } from '@bpm-web-app/download-api-sdk';
import { Period, useTopSources } from '@bpm-web-app/swr-hooks';
import { capitalizeFirstLetter } from '@bpm-web-app/utils';
import { useMemo, useState } from 'react';
import ArtistPortalList, { SourcesAttributes } from '../../../artist-portal-list/artist-portal-list';
import { ArtistPortalListPreset } from '../../../artist-portal-list/artist-portal-list-helpers';
import Dropdown from '../../../dropdown/dropdown';
import BPMIcons from '../../../shared/bpm-icons/bpm-icons';
import { TitleView } from '../title-view/title-view';
import styles from './top-sources.module.css';
import { GhostComponent } from '../../../shared';

export function ArtistTopSources() {
    const [period, setPeriod] = useState<Period>('week');
    const [sortType, setSortType] = useState<ArtistAlbumsSortByQueryOptions | ArtistSortByQueryOptions>(ArtistSortByQueryOptions.Downloads);
    const { data } = useTopSources(period, sortType as ArtistSortByQueryOptions);

    const allPeriods: Period[] = ['day', 'week', 'month'];

    const titleView = <TitleView
        title="Top Sources"
        subtitle="View where your plays, downloads, and impressions are coming from."
        rightComponent={<Dropdown<Period>
            onClick={(option) => {
                setPeriod(option);
            }}
            value={period}
            useNewMobileStyle
            options={[
                ...allPeriods.map((p) => ({
                    label: capitalizeFirstLetter(p),
                    value: p
                }))
            ]}
        />}
    />;

    const getIconFrom = (sourceTitle: string) => {
        switch (sourceTitle.replace(/ /gi, '').toLowerCase()) {
            case 'album':
                return <BPMIcons.AlbumIcon />;
            case 'artist':
                return <BPMIcons.ArtistIcon />;
            case 'category':
                return <BPMIcons.CategoryIcon />;
            case 'crate':
                return <BPMIcons.CrateIcon />;
            case 'curatedsets':
                return <BPMIcons.CuratedSetsIcon />;
            case 'foryou':
                return <BPMIcons.ForYouIcon />;
            case 'genre':
                return <BPMIcons.GenreIcon />;
            case 'myplaylists':
                return <BPMIcons.MyPlaylistsIcon />;
            case 'newreleases':
                return <BPMIcons.NewReleasesIcon />;
            case 'playlists':
                return <BPMIcons.PlaylistsIcon />;
            case 'search':
                return <BPMIcons.SearchIcon />;
            case 'trending':
                return <BPMIcons.TrendingIcon />;
            case 'unknown':
                return <BPMIcons.UnknownIcon />;
            default:
                return <BPMIcons.UnknownIcon />;
        }
    };

    const topSourcesData = useMemo(() => {
        return data?.data?.map((source) => {
            return {
                ...source,
                source: source.from,
                icon: getIconFrom(source.from)
            } as SourcesAttributes;
        }) || [];
    }, [data]);

    return (
        <div className={styles['top-sources']}>
            {titleView}
            {data ? <ArtistPortalList setSortType={setSortType} sortType={sortType} preset={ArtistPortalListPreset.Sources} list={topSourcesData} /> : <GhostComponent type="track-list" elementsCount={5} isArtistPortal preset={ArtistPortalListPreset.Sources} />}
        </div>
    );
}
