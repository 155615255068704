import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Album as StreamAlbum, MediaWithAlbum } from '@bpm-web-app/stream-api-sdk';
import { Album as DownloadAlbum, MediaWithAlbum as DownloadMediaWithAlbum, Media as DownloadMedia, UserPlaylistCollaboration, UserPlaylistWithAlbumOwner, Album, SortByQueryOptions } from '@bpm-web-app/download-api-sdk';
import { QueueItem, rebuildReactTooltip, useHubSwitch, useUserSettings, useViewport } from '@bpm-web-app/utils';
import { useDrop } from 'react-dnd';
import { SearchPaginatedQuery } from '@bpm-web-app/api-client';
import { TrackListItem, TrackListItemSelectableProps } from './track-list-item';
import styles from './track-list.module.css';
import { columnDefinitions, ColumnType, TrackListPreset } from './track-list-helpers';
import { Checkbox } from '../ui/checkbox/checkbox';
import SortOptionsSheet, { APISortingKeys, LocalSortingKeys, SortOption } from '../../sort-options-sheet/sort-options-sheet';

import { SortButton } from '../sort-button/sort-button';
import { DragDropItem } from '../../droppable/droppable';
import { TrackListFilters } from './track-list-filters/track-list-filters';
import { TrackListGhostLoading } from './ghost-loading/track-list-ghost-loading';
import { CustomIcon } from '../custom-icon/custom-icon';

/* this is to simplify imports in other files where TrackList is used */
export { TrackListPreset } from './track-list-helpers';

interface TrackListBaseProps {
    list?: Array<MediaWithAlbum> | Array<DownloadAlbum> | Array<DownloadMediaWithAlbum> | Array<QueueItem>;
    preset: TrackListPreset;
    album?: StreamAlbum;
    isUserPlaylist?: boolean;
    isSortable?: boolean;
    onSort?: (type: LocalSortingKeys | APISortingKeys) => void;
    selectedSortType?: LocalSortingKeys | APISortingKeys;
    sortOptions?: SortOption<LocalSortingKeys | APISortingKeys | any>[];
    onDownloadRevalidate?: (downloadMedia?: DownloadMedia) => void;
    defaultExpanded?: number;
    trendingStatusToShow?: 'trending_status_weekly' | 'trending_status_daily' | 'trending_status_monthly'
    onFilterApplied?: (filter: { bpm?: number, key?: string, genre?: string }) => void;
    collaborators?: UserPlaylistCollaboration[];
    owner?: UserPlaylistWithAlbumOwner;
    onMove?: (mediaId: number, prevIndex: number, targetIndex: number) => void;
    hasEditAccess?: boolean;
    hasFilters?: boolean;
    showActiveFilters?: boolean;
    defaultFilters?: Partial<SearchPaginatedQuery>
    isUpdatingList?: boolean;
    isLoading?: boolean;
    resultsCount?: number
    onFiltersSelected?: () => void;
    hasColumnSorting?: boolean;
}

export interface TrackListDraggableProps extends TrackListBaseProps {
    isSortable?: boolean;
    isDraggable?: boolean;
}

export interface TrackListNonSelectableProps extends TrackListBaseProps {
    onSelectItem?: never;
    selectedItemsIds?: never;
    onSelectAll?: never;
}

export interface TrackListSelectableProps extends TrackListBaseProps {
    /** Sets the TrackList with the selectable variant */
    onSelectItem: (mediaId: number | string, index: number) => void;
    selectedItemsIds: string[] | number[];
    onSelectAll: (checked: boolean) => void;
}

/* This type definition is not 100% spot on - the issues with draggable/non-draggable props
 * are not being correctly flagged (a different error gets shown - unrelated to missing/redundant
 * props for a Draggable feature).
 * TODO: figure this out */
export type TrackListProps = (TrackListNonSelectableProps | TrackListSelectableProps) & TrackListDraggableProps;

/* We need to support both albums and a list of tracks.
 * Albums are a bit different as they contain some properties like 'genre'
 * or 'is_exclusive' on the root level (instead of each media having its own field).
 * TODO: potentially make this more consistent */
function isAlbum(album: StreamAlbum | undefined): album is StreamAlbum {
    return album !== undefined;
}

export function TrackList({
    list,
    preset,
    album,
    isSortable,
    onSelectItem,
    selectedItemsIds,
    onSelectAll,
    isUserPlaylist = false,
    isDraggable,
    onSort,
    onDownloadRevalidate,
    selectedSortType,
    sortOptions,
    defaultExpanded,
    onFilterApplied,
    trendingStatusToShow,
    collaborators,
    owner,
    onMove,
    hasEditAccess,
    hasFilters,
    isUpdatingList,
    isLoading,
    showActiveFilters,
    resultsCount,
    defaultFilters,
    onFiltersSelected,
    hasColumnSorting,
}: TrackListProps) {
    const streamAlbum = album as StreamAlbum;
    const streamTracksList = isAlbum(streamAlbum) ? streamAlbum.media : (list as MediaWithAlbum[]);
    const downloadTracksList = list as DownloadAlbum[];
    const queueTracksList = list as QueueItem[];
    const offlineCrateTracksList = list as DownloadMediaWithAlbum[];
    const { isDownload } = useHubSwitch();
    const [sortVisible, setSortVisible] = useState(false);
    const [hasToggledSort, setHasToggledSort] = useState(false);
    const [sortPosition, setSortPosition] = useState({ left: 0, top: 0 });
    const { isAnonymous, setSelectedMedia, setShowSignUpModal } = useUserSettings();

    const { isMobile } = useViewport();

    let tracksList: DownloadAlbum[] | MediaWithAlbum[] | QueueItem[] | DownloadMediaWithAlbum[];
    switch (preset) {
        case TrackListPreset.Download:
            tracksList = downloadTracksList;
            break;
        case TrackListPreset.Stream:
            tracksList = streamTracksList;
            break;
        case TrackListPreset.Queue:
            tracksList = queueTracksList;
            break;
        case TrackListPreset.OnlineCrate:
            tracksList = offlineCrateTracksList;
            break;
        default:
            tracksList = streamTracksList;
            break;
    }

    const setCurrentMediaInContext = useCallback((id: string, showSignUpModal?: boolean) => {
        if (tracksList && tracksList.length > 0) {
            const currentCenterMedia = (tracksList as DownloadAlbum[]).find((item) => `${item.id}` === id);
            if (currentCenterMedia) setSelectedMedia((currentCenterMedia as DownloadAlbum), tracksList as DownloadAlbum[]);

            if (showSignUpModal) {
                setShowSignUpModal({ type: 'track' });
            }
        }
    }, [setSelectedMedia, setShowSignUpModal, tracksList]);

    useEffect(() => {
        rebuildReactTooltip();
        if (tracksList as DownloadAlbum[] && (tracksList as DownloadAlbum[])[0] && (tracksList as DownloadAlbum[])[0].id) setCurrentMediaInContext(`${(tracksList as DownloadAlbum[])[0].id}`, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectableProps = useCallback(
        (mediaId: number | string, trackIndex: number) => {
            if (onSelectItem) {
                return {
                    onSelectItem: () => (onSelectItem ? onSelectItem(mediaId, trackIndex) : undefined),
                    isSelected: selectedItemsIds?.map(String).includes(String(mediaId)),
                } as Pick<TrackListItemSelectableProps, 'onSelectItem' | 'isSelected'>;
            }
            return {};
        },
        [onSelectItem, selectedItemsIds]
    );

    const selectAllCheckbox = useMemo(
        () => (
            <Checkbox
                checked={!!selectedItemsIds?.length && selectedItemsIds?.length === list?.length}
                onChange={(e) => {
                    if (onSelectAll) onSelectAll(e.target.checked);
                }}
            />
        ),
        [list?.length, onSelectAll, selectedItemsIds?.length]
    );

    const columnHeadingClickableLabel = useCallback((type, heading) => {
        const onClickAction = () => {
            setHasToggledSort(true);
            switch (type) {
                case ColumnType.Title:
                    if (selectedSortType === SortByQueryOptions.TitleAsc) onSort?.(SortByQueryOptions.TitleDesc);
                    else onSort?.(SortByQueryOptions.TitleAsc);
                    break;
                case ColumnType.Bpm:
                    if (selectedSortType === SortByQueryOptions.BpmAsc) onSort?.(SortByQueryOptions.BpmDesc);
                    else onSort?.(SortByQueryOptions.BpmAsc);
                    break;
                case ColumnType.Date:
                    if (!hasToggledSort) onSort?.(SortByQueryOptions.DateDesc);
                    else if (selectedSortType === SortByQueryOptions.DateDesc) onSort?.(SortByQueryOptions.DateAsc);
                    else onSort?.(SortByQueryOptions.DateDesc);
                    break;
                default:
                    break;
            }
        };

        const getActiveState = () => {
            switch (type) {
                case ColumnType.Title:
                    if (selectedSortType === SortByQueryOptions.TitleDesc) return 'up';
                    if (selectedSortType === SortByQueryOptions.TitleAsc) return 'down';
                    return undefined;
                case ColumnType.Bpm:
                    if (selectedSortType === SortByQueryOptions.BpmDesc) return 'up';
                    if (selectedSortType === SortByQueryOptions.BpmAsc) return 'down';
                    return undefined;
                case ColumnType.Date:
                    if (selectedSortType === SortByQueryOptions.DateDesc && hasToggledSort) return 'down'; // Default sort type
                    if (selectedSortType === SortByQueryOptions.DateAsc) return 'up';
                    return undefined; default:
                    return undefined;
            }
        };
        if (!onSort) return heading;
        return (
            <button className={classNames(styles['track-list__clickable-label'], { [styles['track-list__clickable-label--active']]: getActiveState() })} type="button" onClick={onClickAction}>
                {heading}
                {getActiveState() ? (
                    <CustomIcon type="chevron-right-icon" color="dynamic" size={20} iconRotation={getActiveState() === 'up' ? -90 : 90} hasIconHover />
                ) : null}
            </button>
        );
    }, [hasToggledSort, onSort, selectedSortType]);

    const getColumnHeading = useCallback(
        (type, heading) => {
            switch (type) {
                case ColumnType.EditModeCheckbox:
                    return onSelectItem ? selectAllCheckbox : heading;
                case ColumnType.QueueDownloadVersion:
                    return isDownload ? heading : '';

                case ColumnType.StreamActions:
                case ColumnType.DownloadActions:
                case ColumnType.OfflineCrateActions:
                case ColumnType.OnlineCrateActions: {
                    if (!isSortable) {
                        return null;
                    }
                    if (isAnonymous) {
                        return null;
                    }
                    return (
                        <SortButton
                            componentClass={styles['track-list__sort-btn']}
                            setSortPosition={setSortPosition}
                            setSortVisible={setSortVisible}
                            selectedSortType={selectedSortType}
                            sortOptions={sortOptions}
                        />
                    );
                }
                case ColumnType.Title:
                case ColumnType.Bpm:
                case ColumnType.Date:
                    // MARK: Temporarily disabling Column sorting.
                    // if (hasColumnSorting) return columnHeadingClickableLabel(type, heading);
                    return heading;
                default:
                    return heading;
            }
        },
        [onSelectItem, selectAllCheckbox, isDownload, isSortable, isAnonymous, selectedSortType, sortOptions]
    );

    const [{ isOver }, dropRef] = useDrop<DragDropItem, any, { isOver: boolean }>({
        accept: (preset === TrackListPreset.Queue ? 'Queue' : 'UserPlaylist-Album'),
        collect: (monitor) => ({
            isOver: monitor.isOver()
        }),
        canDrop: () => {
            return !!isDraggable;
        },
        drop: (data: any) => {
            onMove?.(data.id, data.prevIndex, 0);
        }
    });

    const renderTrackListHeader = useMemo(() => {
        return (
            !isMobile ? (
                <div
                    ref={dropRef}
                    className={classNames(styles['track-list__list-heading'], styles[`track-list__list-heading--${preset}`], {
                        [styles['track-list__list-heading--sortable']]: isSortable,
                        [styles['track-list__list-heading--dragging-over']]: isOver
                    })}
                >
                    {columnDefinitions[preset]?.map(({ heading, type, class: className }, index) => (
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            className={classNames(className || '', styles['track-list__heading-column'], styles[`track-list__heading-column--${type}`])}
                        >
                            {getColumnHeading(type, heading)}
                        </div>
                    ))}
                </div>
            ) : null
        );
    }, [dropRef, getColumnHeading, isMobile, isOver, isSortable, preset]);

    return (
        <div className={classNames(styles['track-list'])}>
            {hasFilters ? <TrackListFilters
                dynamicActiveTabColor
                showActiveFilters={showActiveFilters}
                resultsCount={resultsCount}
                defaultFilters={defaultFilters}
                onActionSelected={onFiltersSelected} /> : null}
            {isSortable && (
                <SortOptionsSheet
                    leftPosition={sortPosition.left}
                    topPosition={sortPosition.top}
                    selectedAction={selectedSortType}
                    onAction={(actionType) => {
                        setSortVisible(false);
                        onSort?.(actionType);
                    }}
                    visible={sortVisible}
                    setVisible={setSortVisible}
                    options={sortOptions || undefined}
                />
            )}
            {renderTrackListHeader}
            {isLoading || isUpdatingList ? (<TrackListGhostLoading amount={10} isMobile={isMobile} />) : null}

            {!isLoading && tracksList?.length > 0 ? (
                <div className={styles['track-list__list']}>
                    {tracksList?.map((media, trackIndex) => {
                        const collaborator = collaborators?.find((current) => current.id === (media as DownloadAlbum).added_by_key);
                        return (
                            <TrackListItem
                                hasPremiumOnlyAccess={(media as Album).has_premium_only_access}
                                isPremiumOnly={(media as Album).is_premium_only}
                                setCurrentMediaInContext={setCurrentMediaInContext}
                                key={media.id}
                                media={media}
                                collaborator={collaborator}
                                owner={owner}
                                isSharedPlaylist={collaborators && collaborators.length > 0}
                                hasEditAccess={isUserPlaylist || (hasEditAccess && collaborator?.is_own)}
                                addedAt={(media as DownloadAlbum).added_at}
                                preset={preset}
                                streamTracksList={streamTracksList}
                                downloadTracksList={downloadTracksList}
                                trackIndex={trackIndex}
                                streamAlbum={preset === TrackListPreset.Download ? undefined : album}
                                isUserPlaylist={isUserPlaylist}
                                onDownloadRevalidate={onDownloadRevalidate}
                                defaultExpanded={defaultExpanded}
                                onFilterApplied={onFilterApplied}
                                trendingStatusToShow={trendingStatusToShow}
                                isDraggable={isDraggable}
                                onMove={(prevIndex, mediaId) => {
                                    let newIndex = trackIndex;
                                    if (trackIndex < prevIndex) {
                                        newIndex += 1;
                                    }
                                    onMove?.(mediaId, prevIndex, newIndex);
                                }}
                                {...selectableProps(preset === TrackListPreset.Queue ? (media as QueueItem).uuid : media.id, trackIndex)}
                            />
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
}

export default TrackList;
